/* WIDTH AND HEIGHT */
.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}
